<template>
	<div @click.stop="clickHandel">
		<slot></slot>
		<span @click.stop="">
			<div class="GobalBuyer flex flex-col" v-if="Visible">
				<div class="flex flex-ac pt20 pb20 pl15 box">
					<div class="pad15" @click.stop="cancleHandel">
						<img class="db" src="../images/back.png" />
					</div>
					<span class="ft20 c333 bold">查看权限</span>
				</div>
				<div class="it1 box overflow">
					<div class="h100">
						<div class="permission pl30 box">
							<div class="pl20 pb20 box">
								<el-tree 
									ref="funsTree"
									:data="data" 
									node-key="id" 
									props="defaultProps"
									:show-checkbox="true"
								>
								</el-tree>
							</div>
						</div>
						<div class="edit-role-div">
							<el-button class="edit-button" type="primary" :disabled="roleId === 1" @click.stop="editRoleFuncs">修改权限</el-button>
						</div>
					</div>
				</div>
			</div>
		</span>
	</div>
</template>

<script>
	import axios from '@/utils/axios';
	import './GobalBuyer.less';
	export default {
		name: 'GobalBuyer',
		props: {
			roleId: {
				type: Number,
				default: -1
			}
		},
		data() {
			return {
				Visible: false,
				name: '',
				textarea: '',
				data: [
					{
						id: 'work_bench',
						label: '工作台',
						disabled: false
					}, 
					{
						id: 'big_screen',
						label: '大屏',
						disabled: false
					},
					{
						id: 'operation_monitoring',
						label: '运维监控',
						disabled: false
					},
					{
						id: 'workorder_management',
						label: '工单管理',
						disabled: false,
						children: [{
							id: 'trouble_shooting',
							label: '故障救援',
							disabled: false
						}, {
							id: 'planned_maintenance',
							label: '计划检修',
							disabled: false
						},{
							id: 'back_job',
							label: '回调工单',
							disabled: false
						},{
							id: 'sale_job',
							label: '售后工单',
							disabled: false
						},{
							id: 'mine_job',
							label: '我的工单',
							disabled: false
						}]
					},
					{
						id: 'customer_center',
						label: '客服中心',
						disabled: false,
						children: [{
							id: 'customer_record',
							label: '客服记录',
							disabled: false
						}, {
							id: 'knowledge_base',
							label: '知识库管理',
							disabled: false
						}]
					},
					{
						id: 'state_management',
						label: '状态管理',
						disabled: false,
						children: [{
							id: 'status_management',
							label: '状态管理',
							disabled: false
						}, {
							id: 'device_control',
							label: '设备控制',
							disabled: false
						}]
					},
					{
						id: 'asset_management',
						label: '运维管理',
						disabled: false,
						children: [
							{
								id: 'site_management',
								label: '站点管理',
								disabled: false,
							}, {
								id: 'battery_management',
								label: '电池管理',
								disabled: false,
							},
							{
								id: 'operation_team_management',
								label: '运维班组管理',
								disabled: false,
							}, {
								id: 'operation_personnel_management',
								label: '运维人员管理',
								disabled: false,
							},
							{
								id: 'device_management',
								label: '设备管理',
								disabled: false,
							}, {
								id: 'supplier_management',
								label: '供应商管理',
								disabled: false,
							},
						]
					},
					{
						id: 'message_center',
						label: '信息中心',
						disabled: false,
						children: [{
							id: 'message_list',
							label: '告警消息',
							disabled: false,
						}, {
							id: 'message_management',
							label: '公告消息',
							disabled: false,
						},{
							id: 'un_online_msg',
							label: '离线消息',
							disabled: false,
						},{
							id: 'cloud_control',
							label: '云控信息',
							disabled: false,
						},{
							id: 'customer_information',
							label: '客服信息',
							disabled: false,
						}]
					},
					{
						id: 'promotion_management',
						label: '升级管理',
						disabled: false,
						children: [{
							id: 'upgrade_management',
							label: '升级管理',
							disabled: false,
						}, {
							id: 'upgrade_log',
							label: '升级日志',
							disabled: false,
						},{
							id: 'version_management',
							label: '版本管理',
							disabled: false,
						}]
					}, 
					{
						id: 'system_settings',
						label: '系统设置',
						disabled: false,
						children: [
							{
								id: 'basic_settings',
								label: '消息设置',
								disabled: false,
							},
							{
								id: 'organization_management',
								label: '组织管理',
								disabled: false,
							}, {
								id: 'user_management',
								label: '用户管理',
								disabled: false,
							},
							{
								id: 'role_management',
								label: '角色管理',
								disabled: false,
							}, {
								id: 'login_log_management',
								label: '登录日志管理',
								disabled: false,
							},
						]
					}, 
				],
				defaultProps: {
					children: 'children',
					label: 'label',
				},
			}
		},
		methods: {
			clickHandel() {
				this.Visible = true;
				axios.get(`/api/rolePermissions/getRolePermissionsList/${this.roleId}`).then(rs => {
					const funs = rs.map(item => item.permissionsCode);
					this.$refs.funsTree.setCheckedKeys(funs);
				});
			},
			cancleHandel(){
				this.Visible = false;
			},
			editRoleFuncs() {
				const funs = this.$refs.funsTree.getCheckedKeys();
				const funsHalf = this.$refs.funsTree.getHalfCheckedKeys();
				const list = [...funs, ...funsHalf].map(item => ({ roleId: this.roleId, permissionsCode: item }));
				axios.post('/api/rolePermissions/updateRolePermissions',{ list }).then(rs => {
					this.$message.success('修改成功，角色用户重新登录后生效');
					this.Visible = false;
				}).catch(err => {
					this.$message.error(err);
				});
			}
		}
	}
</script>

<style>
</style>

