<template>
	<div @click.stop="clickHandel">
		<slot></slot>
		<span @click.stop="">
			<el-dialog class="neweldialog" title="提示" :visible.sync="dialogVisible" width="337px">
				<div class="ftc ft14 mt20">确定要删除角色吗？</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false;">取 消</el-button>
					<el-button type="primary" @click="sureHandel">确 定</el-button>
				</span>
			</el-dialog>
		</span>
	</div>
</template>

<script>
	import './Cancle.less';
	import axios from '@/utils/axios';
	export default {
		name: 'Cancle',
		props: {
			roleId: {
				type: Number,
				default: -1
			},
			getData: {
				type: Function,
				default: () => {}
			}
		},
		data() {
			return {
				dialogVisible: false,
			}
		},
		methods: {
			clickHandel() {
				this.dialogVisible = true;
			},
			sureHandel(){
				axios.post(`/api/role/remove/${this.roleId}`, { id: this.roleId }).then(rs => {
					this.$message.success('删除成功');
					this.getData();
					this.dialogVisible = false;
				}).catch(err => {
					this.$message.error(err);
				})
			}
		}
	}
</script>

<style>
</style>
