<template>
	<div class="role-management-container">
		<div class="flex flex-ac flex-pj">
			<!-- 添加 -->
			<AddWin :getData="getData">
				<el-button type="primary">添加</el-button>
			</AddWin>
		</div>
		<div class="lists flex flex-wrap">
			<div class="flex flex-col flex-ac" v-for="(item,index) in roleList" :key="index">
				<div class="handle w100">
					<div class="rela">
						<div class="abs handleimg">
							<div class="set pad5">
								<span class="icon"></span>
							</div>
						</div>
					</div>
					<GobalBuyer :roleId="item.id">
						<div class="rela">
							<div class="setEvent abs ft14 c666 ftc">查看权限</div>
						</div>
					</GobalBuyer>
				</div>
				<img class="head" src="./images/head.png" />
				<span class="ft20 c333 bold">{{item.roleName}}</span>
				<span class="ft14 c999 mt10 jobDescription">{{item.jobDescription}}</span>
				<div class="mt25 ft16 c666">
					<span>该角色目前已添加</span>
					<span class="c0096FF">{{item.roleUserCount ? item.roleUserCount : 0}}</span>
					<span>个账号</span>
				</div>
				<div class="flex flex-ac flex-pc buttons">
					<Cancle :roleId="item.id" :getData="getData">
						<el-button plain v-if="currentRole !== item.id">删除</el-button>
					</Cancle>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import './Index.less';
	import AddWin from './components/AddWin';
	import Cancle from './components/Cancle';
	import GobalBuyer from './components/GobalBuyer';
	import axios from '@/utils/axios';
	export default {
		name: 'UserManagementIndex',
		components: {
			AddWin,
			Cancle,
			GobalBuyer
		},
		data() {
			return {
				roleList: [],
				organId: null,
			}
		},
		computed: {
			currentRole() {
				let _roleId = -1;
				const currentUserStr = localStorage.getItem('currentUser');
				if (currentUserStr) {
					const { roleId } = JSON.parse(currentUserStr);
					_roleId  = roleId
				}
				return _roleId;
			}
		},
		watch: {

		},
		mounted() {
			this.organId = localStorage.getItem('organId')
			this.getData();
		},
		methods: {
			/**
			 * 查询所有角色
			 */
			getData() {
				axios.post('/api/role/getList', {id: this.organId}).then(rs => {
					this.roleList = rs;
				}).catch(err => {
					this.$message.error(err);
				});
			},
			/**
			 * 添加
			 */
			AddEvent() {

			}
		},
	}
</script>

<style>
</style>
